define("bob-lewis-ui/routes/home/projects/universes", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900/", photos: ["v1450264299/MainSlideshow/0_copy_2.jpg", "v1449886223/MainSlideshow/tu-1_copy.jpg", "v1450312475/MainSlideshow/_MG_6455-2.jpg", "v1449886223/MainSlideshow/tu-1b_copy_2.jpg", "v1449886223/MainSlideshow/tu-1c_copy.jpg", "v1449886223/MainSlideshow/tu-2_copy.jpg", "v1449886223/MainSlideshow/tu-3a_copy_2.jpg", "v1449886223/MainSlideshow/tu-6_copy_4.jpg", "v1449886223/MainSlideshow/tu-7a_copy.jpg", "v1449886223/MainSlideshow/tu-7_copy_4.jpg", "v1449886223/MainSlideshow/tu-29_copy.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});