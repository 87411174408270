define("bob-lewis-ui/routes/home/projects/northern-exposures", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900", photos: ["v1576121033/temporal2019/amtrak/1.jpg", "v1576121033/temporal2019/amtrak/2.jpg", "v1576121033/temporal2019/amtrak/3.jpg", "v1576121033/temporal2019/amtrak/4.jpg", "v1576121033/temporal2019/amtrak/5.jpg", "v1576121033/temporal2019/amtrak/6.jpg", "v1576121033/temporal2019/amtrak/7.jpg", "v1576121033/temporal2019/amtrak/8.jpg", "v1576121033/temporal2019/amtrak/9.jpg", "v1576121033/temporal2019/amtrak/10.jpg", "v1576121033/temporal2019/amtrak/11.jpg", "v1576121033/temporal2019/amtrak/12.jpg", "v1576121033/temporal2019/amtrak/13.jpg", "v1576121033/temporal2019/amtrak/14.jpg", "v1576121033/temporal2019/amtrak/15.jpg", "v1576121033/temporal2019/amtrak/16.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});