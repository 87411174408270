define("bob-lewis-ui/routes/home/projects/transcending-truckness", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900", photos: ["v1508123011/TranscendingTruckness/01.jpg", "v1508123011/TranscendingTruckness/02.jpg", "v1508123011/TranscendingTruckness/03.jpg", "v1508123011/TranscendingTruckness/04.jpg", "v1508123011/TranscendingTruckness/05.jpg", "v1508123011/TranscendingTruckness/06.jpg", "v1508123011/TranscendingTruckness/07.jpg", "v1508123011/TranscendingTruckness/08.jpg", "v1508123011/TranscendingTruckness/09.jpg", "v1508123011/TranscendingTruckness/10.jpg", "v1508123011/TranscendingTruckness/11.jpg", "v1508123011/TranscendingTruckness/12.jpg", "v1508123011/TranscendingTruckness/13.jpg", "v1508123011/TranscendingTruckness/14.jpg", "v1508123011/TranscendingTruckness/15.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});