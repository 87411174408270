define("bob-lewis-ui/routes/home/projects/below-seawall", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900/", photos: ["/v1449887272/MainSlideshow/bsalt-1.jpg", "/v1449887272/MainSlideshow/bsalt-2.jpg", "/v1449887272/MainSlideshow/bsalt-3.jpg", "/v1449887272/MainSlideshow/bsalt-5.jpg", "/v1449887272/MainSlideshow/bsalt-6.jpg",
        // "/v1449887272/MainSlideshow/bsalt-6_copy.jpg",
        "/v1449887272/MainSlideshow/bsalt-8.jpg", "/v1449887272/MainSlideshow/bsalt-9.jpg", "/v1449887272/MainSlideshow/bsalt-10.jpg", "/v1449887272/MainSlideshow/bsalt-11.jpg", "/v1449887272/MainSlideshow/bsalt-25.jpg", "/v1449887272/MainSlideshow/bsalt-20090530-_MG_5863-Edit_copy.jpg", "/v1449887272/MainSlideshow/bsalt-20100917-_MG_8289.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});