define("bob-lewis-ui/routes/home/projects/american-scene", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900/", photos: ["v1450313161/MainSlideshow/1_copy_2.jpg", "v1450313158/MainSlideshow/2_copy_3.jpg", "v1450313156/MainSlideshow/3_copy_2.jpg", "v1450313158/MainSlideshow/4a_copy_2.jpg", "v1450313158/MainSlideshow/5_copy_2.jpg", "v1450313159/MainSlideshow/6_copy_3.jpg", "v1450313158/MainSlideshow/7_copy_3.jpg", "v1450313162/MainSlideshow/8_copy_4.jpg", "v1450313159/MainSlideshow/9_copy_3.jpg", "v1449886540/MainSlideshow/aas-10.jpg", "v1449886540/MainSlideshow/aas-11.jpg", "v1449886540/MainSlideshow/aas-12.jpg", "v1449886540/MainSlideshow/aas-13.jpg", "v1449886540/MainSlideshow/aas-14.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});