define("bob-lewis-ui/routes/home/projects/temporal-universes", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900", photos: ["v1576120848/temporal2019/1.jpg", "v1576120848/temporal2019/2.jpg", "v1576120848/temporal2019/3.jpg", "v1576120848/temporal2019/4.jpg", "v1576120848/temporal2019/5.jpg", "v1576120848/temporal2019/6.jpg", "v1576120848/temporal2019/7.jpg", "v1576120848/temporal2019/8.jpg", "v1576120848/temporal2019/9.jpg", "v1576120848/temporal2019/10.jpg", "v1576120848/temporal2019/11.jpg", "v1576120848/temporal2019/12.jpg", "v1576120848/temporal2019/13.jpg", "v1576120848/temporal2019/14.jpg", "v1576120848/temporal2019/15.jpg", "v1576120848/temporal2019/16.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});