define('bob-lewis-ui/router', ['exports', 'bob-lewis-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('home', { path: '/' }, function () {
      this.route('about', function () {
        this.route('statement');
        this.route('bio');
        this.route('cv');
      });
      this.route('projects', function () {
        // my moving window
        this.route('islands');
        this.route('wales');
        this.route('northern-exposures');
        this.route('siberian-stillness');
        this.route('transcending-truckness');
        this.route('temporal-universes');
        this.route('roadside-gardens');

        this.route('american-scene');
        this.route('elsewhere');
        this.route('trucks-trailers');
        this.route('morning-flatland');
        // accidental-occasions
        this.route('universes');
        this.route('presence');
        this.route('below-seawall');
        this.route('oaxacan-markets');
      });
      this.route('contact');
    });
  });

  exports.default = Router;
});