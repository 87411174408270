define("bob-lewis-ui/routes/home/projects/morning-flatland", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900/", photos: ["v1449886320/MainSlideshow/mctfl-1.jpg", "v1449886320/MainSlideshow/mctfl-2.jpg", "v1449886320/MainSlideshow/mctfl-3.jpg", "v1449886320/MainSlideshow/mctfl-4.jpg", "v1449886320/MainSlideshow/mctfl-5.jpg", "v1449886320/MainSlideshow/mctfl-6.jpg", "v1449886320/MainSlideshow/mctfl-7.jpg", "v1449886320/MainSlideshow/mctfl-8.jpg", "v1449886320/MainSlideshow/mctfl-9.jpg", "v1449886320/MainSlideshow/mctfl-10.jpg", "v1449886320/MainSlideshow/mctfl-11.jpg", "v1449886320/MainSlideshow/mctfl-12.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});