define('bob-lewis-ui/components/slide-show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNameBindings: ['wide:wide'],
    wide: false,

    init: function init() {
      this._super();
      this.setProperties({
        index: 0,
        slideshow: null,
        playPauseLabel: "pause"
      });
      this.playSlides();
    },
    playSlides: function playSlides() {
      var _this = this;

      var slideshow = null;
      var label;
      if (this.get('slideshow')) {
        label = "play";
        window.clearInterval(this.get('slideshow'));
      } else {
        label = "pause";
        slideshow = window.setInterval(function () {
          Em.run.next(function () {
            _this.nextPhoto();
          });
        }, 5000);
      }
      this.setProperties({
        slideshow: slideshow,
        playPauseLabel: label
      });
    },
    reset: function reset() {
      this.setProperties({ folderIndex: 0, photosIndex: 0 });
    },


    folder: function () {
      var photos = this.get('photos');
      if (photos.length) {
        return photos[this.get('index')][0];
      }
    }.property('photoName'),

    photoName: function () {
      var photos = this.get('photos');
      if (photos.length) {
        return photos[this.get('index')][1];
      }
    }.property('index'),

    photos: function () {
      var folders = [];
      this.get('folders').forEach(function (folder) {
        folder.photos.map(function (photo) {
          folders.pushObject([folder.name, photo]);
        });
      });
      return folders;
    }.property('folders'),

    nextPhoto: function nextPhoto() {
      var index = this.get('index') + 1;
      if (index === this.get('photos').length) {
        this.set('index', 0);
      } else {
        this.set('index', index);
      }
    },
    prevPhoto: function prevPhoto() {
      var index = this.get('index') - 1;
      if (index < 0) {
        this.set('index', this.get('photos').length);
      } else {
        this.set('index', index);
      }
    },


    actions: {
      playPause: function playPause() {
        this.playSlides();
      },
      prevSlide: function prevSlide() {
        this.prevPhoto();
      },
      nextSlide: function nextSlide() {
        this.nextPhoto();
      }
    }
  });
});