define('bob-lewis-ui/routes/home/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/ar_2,c_crop,c_limit,h_900,w_900", photos: Array.from({ length: 10 }, function (_, i) {
          return i + 1;
        }).map(function (i) {
          return 'v1625686108/2021%20June%20updates/' + i + '.jpg';
        }) }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    }
  });
});