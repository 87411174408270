define("bob-lewis-ui/routes/home/projects/elsewhere", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900/", photos: ["v1449886431/MainSlideshow/ew-1.jpg", "v1449886431/MainSlideshow/ew-2.jpg", "v1449886431/MainSlideshow/ew-3.jpg", "v1449886428/MainSlideshow/ew-4.jpg", "v1449886428/MainSlideshow/ew-5.jpg", "v1449886431/MainSlideshow/ew-6.jpg", "v1449886431/MainSlideshow/ew-7.jpg", "v1449886431/MainSlideshow/ew-8.jpg", "v1449886431/MainSlideshow/ew-9.jpg", "v1449886431/MainSlideshow/ew-10.jpg", "v1449886431/MainSlideshow/ew-10a.jpg", "v1449886431/MainSlideshow/ew-10b.jpg", "v1449886431/MainSlideshow/ew-11.jpg", "v1449886431/MainSlideshow/ew-12.jpg", "v1449886431/MainSlideshow/ew-13.jpg", "v1449886431/MainSlideshow/ew-14.jpg", "v1449886431/MainSlideshow/ew-15.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});