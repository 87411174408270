define("bob-lewis-ui/routes/home/projects/presence", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900/", photos: ["v1449887327/MainSlideshow/ao-1.jpg", "v1449887327/MainSlideshow/ao-2.jpg", "v1449887327/MainSlideshow/ao-3.jpg", "v1449887327/MainSlideshow/ao-4.jpg", "v1449887327/MainSlideshow/ao-5.jpg", "v1449887327/MainSlideshow/ao-9.jpg", "v1449887327/MainSlideshow/ao-7.jpg", "v1449887327/MainSlideshow/ao-1_copy.jpg", "v1449887327/MainSlideshow/ao-4_copy_2.jpg", "v1449887327/MainSlideshow/ao-5a_copy.jpg", "v1449887327/MainSlideshow/ao-3_copy_2.jpg", "v1449887327/MainSlideshow/ao-9_copy.jpg", "v1449887327/MainSlideshow/ao-1d_copy.jpg", "v1449887327/MainSlideshow/ao-20110308-_MG_0024-Edit.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});