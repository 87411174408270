define("bob-lewis-ui/routes/home/projects/trucks-trailers", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900/", photos: ["v1450311899/1jpg_copy_yan26m.jpg", "v1450311898/2jpg_copy_vwbmgh.jpg", "v1450311898/3jpg_copy_d97uwf.jpg", "v1450311898/9_copy_4_qthcbf.jpg", "v1450311899/7jpg_copy_zem6e9.jpg", "v1450311899/4jpg_copy_hgnf51.jpg", "v1450311903/7_copy_5_ngk1lr.jpg", "v1449886983/MainSlideshow/toh-10.jpg", "v1449886983/MainSlideshow/toh-11.jpg", "v1449886983/MainSlideshow/toh-12.jpg", "v1449886983/MainSlideshow/toh-13.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});