define("bob-lewis-ui/routes/home/projects/oaxacan-markets", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return [{ name: "http://res.cloudinary.com/digitalpixelpro/image/upload/c_limit,h_900,w_900/", photos: ["v1449887412/MainSlideshow/om-1.jpg", "v1449887412/MainSlideshow/om-2.jpg", "v1449887412/MainSlideshow/om-3.jpg", "v1449887412/MainSlideshow/om-4.jpg", "v1449887412/MainSlideshow/om-5.jpg", "v1449887412/MainSlideshow/om-6.jpg", "v1449887412/MainSlideshow/om-7.jpg", "v1449887412/MainSlideshow/om-8.jpg", "v1449887412/MainSlideshow/om-9.jpg", "v1449887412/MainSlideshow/om-10.jpg", "v1449887412/MainSlideshow/om-11.jpg", "v1449887412/MainSlideshow/om-12.jpg", "v1449887412/MainSlideshow/om-13.jpg", "v1449887412/MainSlideshow/om-42.jpg", "v1449887412/MainSlideshow/om-55.jpg"] }];
    },
    setupController: function setupController(controller, model) {
      controller.set('folders', model);
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});